import React from 'react';

const Home = () => {
    return (      
    <div className="App" id="bruh">
        <h1>
            {"Adem Odza's Website"}
        </h1>
        <div>
        <br />
        </div>
        <body>
            {"I don't have anything to put here yet."} 
        </body>
    </div>
  );
};

export default Home;
